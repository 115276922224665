import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useGlobalStateContext } from "../../common/Context/app";
import Layout from '../../common/components/template/Layout';
import { PageProp } from '../../common/types';
import PagePartRenderer from '../PagePartRender'

const Index: React.FC<PageProp> = ({ data, pageContext }) => {
  const { setSiteConfig } = useGlobalStateContext();
  const pageParts = data?.pageData?.pageParts;

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, [pageContext]);

  return (
    <Layout
      metaDescription={pageContext?.metadata?.page_description || ''}
      metaTitle={pageContext?.metadata?.page_title || ''}
      meta={[]}
      lang={'en'}
    >
      {pageParts?.map?.((part, i) => <PagePartRenderer part={part} key={i} id={i} />)}
    </Layout>
  )
}

export const query = graphql`
  query Page($id: String!) {
    pageData: sanityPage(_id: {eq: $id}) {
      pageParts {

        ... on SanityImageGrid {
          _key
          _type
          navanchor
          title
          gridImages {
            _type
            alt
            image: _rawImage(resolveReferences: {maxDepth: 10})
            _key
          }
        }

        ... on SanityVendorDirectory {
          _key
          _type
          navanchor
          title
        }

        ... on SanityPictureGalleryPageRef {
            _key
            _type
            title
            galleryRef {
              pageParts {
                ... on SanityPictureGallery {
                  _key
                  _type
                  pictures:  _rawPictures(resolveReferences: {maxDepth: 5})
                  display
                  navanchor
                  zoom
                }
              }
            }
          }

        ... on SanityYoutube {
            _key
            _type
            autoplay
            disablekb
            controls
            loop
            modestbranding
            navanchor
            partStyle
            title
            url
        }
        

        ... on SanityCardWide {
          _key
          _type
          navanchor
          title
          image: _rawImage(resolveReferences: {maxDepth: 8})
          body: _rawBody(resolveReferences: {maxDepth: 3})
          cardStyle
          cardHeading
          button {
            _type
            buttonHref
            buttonStyle
            buttonText
            buttonLink {
              slug {
                current
              }
            }
          }
        }

        ... on SanityImageWithTextCallout {
            _key
            _type
            navanchor
            title
            image:_rawImage(resolveReferences: {maxDepth: 5})
            body: _rawBody(resolveReferences: {maxDepth: 5})
          }

        ... on SanityNextEvent {
          _key
          _type
          navanchor
          title
        }

        ... on SanityUpcomingEvents {
          _key
          _type
          title
          navanchor
        }

        ... on SanityHero {
          _key
          image: _rawImage(resolveReferences: {maxDepth: 4})
          navanchor
          _type
          title
        }

        ... on SanityFormPart {
          _key
          _type
          navanchor
          partStyle
          simpleForm {
            _id
            _key
            formId
            title
            formEndpointUrl
            formStyle
            _type
            formParts {

              ... on SanityCheckboxButtonGroup {
                _key
                _type
                groupDescription
                groupCheckboxLayout
                groupName
                groupItems {
                  _type
                  _key
                  disable
                  fieldLabel
                  fieldLabelColour
                  fieldName
                }
                isRequired
                title
              }

              ... on SanityMultiLineInput {
                _key
                _type
                fieldDescription
                fieldLabel
                fieldLabelPlacement
                isRequired
                fieldName
              }

              ... on SanityRadioButtonGroup {
                _key
                _type
                isRequired
                title
                groupName
                groupDescription
                groupItems {
                  _key
                  _type
                  fieldLabel
                  fieldLabelPlacement
                }
              }

              ... on SanitySimpleContentBlock {
                _key
                _type
                image_placement
                navanchor
                partStyle
                title
                image: _rawImage(resolveReferences: {maxDepth: 5})
                button: _rawButton(resolveReferences: {maxDepth: 5})
                body: _rawBody(resolveReferences: {maxDepth: 5})
              }

              ... on SanitySingleInput {
                _key
                _type
                fieldDescription
                fieldLabel
                fieldLabelPlacement
                fieldName
                fieldType
                isRequired
              }

              ... on SanitySelectDropdown {
                _key
                _type
                fieldDescription
                fieldLabel
                fieldName
                isRequired
                dropdownOptions {
                  _key
                  _type
                  fieldLabel
                  fieldValue
                }
              }
            }
          }
          title
        }

        ... on SanitySimpleContentBlock {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 7})
          image: _rawImage
          image_placement
          navanchor
          partStyle
          title
          button {
            _key
            buttonStyle
            buttonText
            buttonHref
            buttonLink {
              title
              slug {
                current
              }
            }
          }
        }

        ... on SanityNotification {
          _key
          _type
          alertLevel
          body: _rawBody(resolveReferences: {maxDepth: 5})
        }

        ... on SanityGalleryAll {
          _key
          _type
          title
          navanchor
          gallery: allGalleryRef {
            pageParts {
              ... on SanityHero {
                _key
                navanchor
                _type
                title
                image: _rawImage(resolveReferences: {maxDepth: 10})
              }
            }
            hideGalleryDate
            title
            slug {
              current
            }
            galleryDate
          }
        }

        ... on SanityGalleryLatest {
          _key
          _type
          title
          navanchor
        }

        ... on SanityPastEventsAll {
          _key
          _type
          navanchor
          title
        }
        ... on SanityUpcomingEventsAll {
          _key
          _type
          navanchor
          title
        }

        ... on SanityReadNextPart {
          _key
          _type
          navanchor
          pages {
            title
            linkTitle
            page {
              slug {
                current
              }
            }
          }
        }

        ... on SanityTabContainerPart {
          _key
          _type
          title
          tabContainerStyle
          tabs {
            title
            name
            navanchor
            iconHint: tabIconHint
            tabParts {
              ... on SanitySimpleContentBlock {
                _type
                image: _rawImage(resolveReferences: {maxDepth: 10})
                body: _rawBody(resolveReferences: {maxDepth: 10})
                image_placement
                button {
                  buttonText
                  buttonStyle
                  buttonHref
                  buttonLink {
                    slug {
                      current
                    }
                    title
                  }
                }
              }

              ... on SanityFormPart {
                _key
                _type
                partStyle
                navanchor
                simpleForm {
                  _id
                  _key
                  formId
                  title
                  formEndpointUrl
                  formStyle
                  _type
                  formParts {
                    ... on SanityCheckboxButtonGroup {
                      _key
                      _type
                      groupDescription
                      isRequired
                      title
                      groupCheckboxLayout
                      groupName
                      groupItems {
                        _key
                        fieldLabel
                        disable
                        _type
                        fieldLabelColour
                        fieldName
                      }
                    }

                    ... on SanityMultiLineInput {
                      _key
                      _type
                      fieldDescription
                      fieldLabel
                      fieldLabelPlacement
                      isRequired
                      fieldName
                    }

                    ... on SanityRadioButtonGroup {
                      _key
                      _type
                      GroupTitleColour
                      groupDescription
                      isRequired
                      groupName
                      title
                      groupItems {
                        _key
                        _type
                        fieldLabel
                        fieldLabelPlacement
                      }
                    }

                    ... on SanitySimpleContentBlock {
                      _key
                      _type
                      image_placement
                      navanchor
                      partStyle
                      body: _rawBody(resolveReferences: {maxDepth: 5})
                      button: _rawButton(resolveReferences: {maxDepth: 5})
                      image: _rawImage(resolveReferences: {maxDepth: 5})
                    }

                    ... on SanitySingleInput {
                      _key
                      _type
                      fieldDescription
                      fieldLabel
                      fieldLabelPlacement
                      fieldName
                      fieldType
                      isRequired
                    }

                    ... on SanitySelectDropdown {
                      _key
                      _type
                      fieldDescription
                      fieldLabel
                      fieldName
                      isRequired
                      dropdownOptions {
                        _key
                        _type
                        fieldLabel
                        fieldValue
                      }
                    }

                  }
                  formId
                }
              }
            }
          }
        }

        ... on SanityTwoColumn {
          _key
          _type
          pageParts {

            ... on SanityFormPart {
              _key
              _type
              navanchor
              partStyle
              simpleForm {
                _id
                _key
                formId
                title
                formEndpointUrl
                formStyle
                _type
                formParts {

                  ... on SanityCheckboxButtonGroup {
                    _key
                    _type
                    groupDescription
                    groupCheckboxLayout
                    groupName
                    groupItems {
                      _type
                      _key
                      disable
                      fieldLabel
                      fieldLabelColour
                      fieldName
                    }
                    isRequired
                    title
                  }

                  ... on SanityMultiLineInput {
                    _key
                    _type
                    fieldDescription
                    fieldLabel
                    fieldLabelPlacement
                    isRequired
                    fieldName
                  }

                  ... on SanityRadioButtonGroup {
                    _key
                    _type
                    isRequired
                    title
                    groupName
                    groupDescription
                    groupItems {
                      _key
                      _type
                      fieldLabel
                      fieldLabelPlacement
                    }
                  }

                  ... on SanitySimpleContentBlock {
                    _key
                    _type
                    image_placement
                    navanchor
                    partStyle
                    title
                    image: _rawImage(resolveReferences: {maxDepth: 5})
                    button: _rawButton(resolveReferences: {maxDepth: 5})
                    body: _rawBody(resolveReferences: {maxDepth: 5})
                  }

                  ... on SanitySingleInput {
                    _key
                    _type
                    fieldDescription
                    fieldLabel
                    fieldLabelPlacement
                    fieldName
                    fieldType
                    isRequired
                  }

                  ... on SanitySelectDropdown {
                    _key
                    _type
                    fieldDescription
                    fieldLabel
                    fieldName
                    isRequired
                    dropdownOptions {
                      _key
                      _type
                      fieldLabel
                      fieldValue
                    }
                  }
                }
              }
              title
            }


            ... on SanitySimpleContentBlock {
              _key
              _type
              image_placement
              navanchor
              partStyle
              title
              image: _rawImage(resolveReferences: {maxDepth: 5})
              button: _rawButton(resolveReferences: {maxDepth: 5})
              body: _rawBody(resolveReferences: {maxDepth: 5})
            }
          }
        }

      }
    }
  }
`

export default Index;
